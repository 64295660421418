import { getCategoryRoute, getProductRoute } from "@shopware-pwa/helpers-next";

export const useCmsUrlResolver = async (url) => {
    let attr = 'href'
    let linkUrl = '/'

    if (!url) {
        return {
            linkUrl,
            attr
        }
    }

    linkUrl = url
    let { search: categorySearch } = useCategorySearch();
    let { search: productSearch } = useProductSearch();
    const localePath = useLocalePath();
    const { formatLink } = useInternationalization(localePath);

    if (url.includes('navigation')) {
        try {
            const parts = url.split('/');
            const categoryResponse = await categorySearch(parts[2], {
                withCmsAssociations: false,
            });

            linkUrl = formatLink(getCategoryRoute(categoryResponse));
            attr = 'to'
        } catch (e) {
            console.error(e)
        }

    }

    if (url.includes('detail')) {
        try {
            const parts = url.split('/');
            const productResponse = await productSearch(parts[2], {
                withCmsAssociations: false,
                criteria: { associations : {
                        seoUrls: {}
                    }
                }
            });
            linkUrl = formatLink(getProductRoute(productResponse.product));
            attr = 'to'
        } catch (e) {
            console.error(e)
        }
    }

    return {
        linkUrl,
        attr
    }
}